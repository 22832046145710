@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

@font-face {
	font-family: 'Grifter';
	src: url(../public/fonts/Grifter/grifter.otf);
	font-weight: normal;
}
@font-face {
	font-family: 'PlusJakartaSans';
	src: url(../public/fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf);
	font-weight: normal;
}
@font-face {
	font-family: 'Inconsolata';
	src: url(../public/fonts/Inconsolata/Inconsolata-VariableFont_wdth\,wght.ttf);
	font-weight: normal;
}

html {
  font-family: 'PlusJakartaSans';
  font-size: 16px;
  scroll-behavior: smooth;
}

.hide_scrollbar::-webkit-scrollbar {
    display: none;
}

.hide_scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.input_clear,
.input_clear:focus {
  border: none;
  background: unset;
  resize: none;
  outline: none;
  color: unset;
  font: unset;
  font-family: inherit;
  font-size: inherit;
}

.set_bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
  
.transitionA {
	transition: all ease 0.25s;
}
.transitionB {
	transition: all linear 0.2s;
}
.transitionC {
	transition: all linear 2s;
}

@media screen and (max-width: 800px) {
  .stickyNavBar.stick {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      padding: 1.5rem 2rem;
      /* background-color: #fbf6de; */
      background-color: white;
      box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.05);
      z-index: 2;
  }
}
/* .animationA {
  animation: showbtn ease linear infinite 1s;
  animation-delay: 3s;
}
@keyframes {

} */


/*for react fast marquee*/
.rfm-marquee {
  z-index: 0 !important;
}
.rfm-initial-child-container {
  display: flex;
  width: 100%;
  justify-content: center;
  justify-content: space-around;
}
.rfm-child {
  display: flex;
  width: 100%;
  justify-content: space-around;
}